/* eslint-disable jsx-a11y/media-has-caption, react/no-danger, jsx-a11y/no-noninteractive-element-interactions */
// @flow
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash.get';

import { SITE_METADATA } from '../../constants';
import styles from '../../styles/pages/column.module.scss';
import Layout from '../../components/Layout/Layout';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { TopBanner } from '../../components';
import banner from '../../images/hero-news.png';
import * as MediaCenterActions from '../../redux/mediaCenter/actions';
import { PreviewSec, ItemsSec } from '../../components/Column/News';

const ColumnPage = ({ location }) => {
  const tags = decodeURIComponent(location.hash.replace('#', ''));
  const fansPage = useSelector((state) => state.mediaCenter.fansPage);
  const fansArticles = useSelector((state) => state.mediaCenter.fansArticles);
  const dispatch = useDispatch();

  // Did mount
  useEffect(() => {
    dispatch(MediaCenterActions.getFansPageAsync());
    dispatch(MediaCenterActions.getFansArticlesAsync());
  }, [dispatch]);

  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.column.title,
        description: SITE_METADATA.column.description,
      }}
    >
      <TopBanner bgImg={banner} textAlign="center">
        <span>コラム</span>
      </TopBanner>
      <Breadcrumb location={location} />

      <>
        <PreviewSec
          title={get(fansPage, 'pageTitle')}
          subtitle={get(fansPage, 'pageSubtitle')}
          card={{
            ...get(fansPage, 'primaryArticle.fields', {}),
            imgUrl: get(
              fansPage,
              'primaryArticle.fields.previewImage.fields.file.url'
            ),
            url: `/column/article/${get(fansPage, 'primaryArticle.sys.id')}`,
            tags: get(fansPage, 'primaryArticle.fields.tags', []).map(
              (item) => item.fields.title
            ),
          }}
        />
        <div className={styles.container}>
          <ItemsSec
            title={get(fansPage, 'secondSectionTitle')}
            tags={get(fansPage, 'tags', [])}
            items={fansArticles}
            selectedTag={tags}
          />
        </div>
      </>
    </Layout>
  );
};

export default ColumnPage;
