// @flow
import React from 'react';
import cx from 'classnames';
import get from 'lodash.get';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import typography from '../../../../styles/typography.module.scss';

import styles from '../NewsComponents.module.scss';

const PreviewSec = ({ card, continueReadingLabel = '記事を読む' }) => {
  return (
    <div className={styles.previewBox}>
      <div className={styles.container}>
        <div className={styles.info}>
          <div className={cx(styles.previewTag, typography.textSmGray)}>
            {get(card, 'tags', []).join(', ')}
          </div>
          {card.title && <h3>{card.title}</h3>}
          {/* <p className={styles.subtitle}>
            {card.subtitle}
          </p> */}
          <div className={styles.showMoreBtn}>
            <OutboundLink className="btn charcoal" href={card.url}>
              {continueReadingLabel}
            </OutboundLink>
          </div>
        </div>
        <OutboundLink className={styles.imgWrapper} href={card.url}>
          <img
            src={get(card, 'previewImage.fields.file.url')}
            alt={'previewImg'}
            width="100%"
            lazyLoad
          />
        </OutboundLink>
      </div>
    </div>
  );
};

export default PreviewSec;
