import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import { Container } from '@material-ui/core';
import { getPathLevels } from '../../utils';

import styles from './Breadcrumb.module.scss';

const Breadcrumb = ({ location = '', customLevels, className = '' }) => {
  const levels = customLevels || getPathLevels(location.pathname);

  return (
    <div className={cx(styles.wrapper, className)}>
      <Container className={styles.container}>
        {levels.map((level, index) => (
          <span key={`breadcrumb-${level.path}`} className={styles.link}>
            {index > 0 && (
              <img
                alt="Chevron Right Icon"
                src={require('../../images/icon-back.svg')}
              />
            )}
            <Link to={level.path}>{level.title}</Link>
          </span>
        ))}
      </Container>
    </div>
  );
};

export default Breadcrumb;
