// @flow
//Core
import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Box, Chip, Button } from '@material-ui/core';
import classNames from 'classnames';
import get from 'lodash.get';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { isMobile as globalIsMobile } from 'react-device-detect';

//Local
import { itemsStyles } from '../styles';
import { filterCardsForPublish } from '../../utils';
import { PaginationColor } from '../../..';
import ColumnPostItem from './ColumnPostItem';

const ItemsSec = ({
  tags,
  items: initialItems,
  loadBy: initialLoadBy = 9,
  selectedTag,
  previewMode,
  hasLoadMore,
  loadMoreBtnLabel,
  loadMoreBtnClassName,
  redirectOnLoadmore,
}: {
  tags: Array<Object>,
  items: Array<Object>,
  loadBy?: number,
  selectedTag?: string,
  previewMode?: boolean,
}) => {
  const styles = itemsStyles({});
  const [items, setItems] = useState(initialItems);
  const [loadBy, setLoadBy] = useState(initialLoadBy);
  const [filterBy, setFilterBy] = useState(selectedTag);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(globalIsMobile);
  });

  const [page, setPage] = useState(0);
  const [filteredItems, setFilteredItem] = useState(
    filterCardsForPublish(items)
  );

  const handleLoadMore = () => {
    if (redirectOnLoadmore) {
      window.location.href = '/hr-blog/';

      return;
    }

    setLoadBy(loadBy + initialLoadBy);
  };

  const handleTagClick = (id, tagTitle) => {
    setLoadBy(initialLoadBy);
    setFilterBy(id);
    window.location.hash = tagTitle;
  };

  useEffect(() => {
    const from = page * loadBy;
    const to = from + loadBy;

    setFilteredItem(filterCardsForPublish(items).slice(from, to));
  }, [page, items, loadBy]);

  const filterItemsByTag = useCallback(
    (data) =>
      data.filter((item) =>
        filterBy
          ? get(item, 'fields.tags', []).find((tag) => tag.sys.id === filterBy)
          : initialItems
      ),
    [filterBy, initialItems]
  );

  useEffect(() => {
    setItems(filterItemsByTag(initialItems));
    setPage(0);
  }, [filterBy, initialItems, filterItemsByTag]);

  useEffect(() => {
    const tag = tags.find((t) => t.fields.title === selectedTag);

    if (!tag) {
      setFilterBy(null);
    } else if (tag.sys.id !== filterBy) {
      setFilterBy(tag.sys.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag, tags, filterBy]);

  const tagsJSX = [
    {
      fields: { title: 'すべての記事' },
      sys: { id: null },
    },
    ...tags,
  ].map((item, index) => (
    <Chip
      label={item.fields.title}
      onClick={() => handleTagClick(item.sys.id, item.fields.title)}
      key={index}
      clickable
      color="secondary"
      className={classNames(isMobile && styles.tagMobile, styles.tag, {
        [styles.activeTag]: item.sys.id === filterBy,
      })}
    />
  ));

  const itemsJSX = filteredItems.map((item, index) => (
    <ColumnPostItem
      itemUrl={`/news/article/${get(item, 'id')}`}
      title={get(item, 'title')}
      subtitle={get(item, 'subtitle')}
      tags={get(item, 'tags', [])
        .map((tag) => get(tag, 'fields.title'))
        .join(', ')}
      previewImage={get(item, 'previewImage.fields.file.url')}
      index={index}
    />
  ));

  return (
    <section
      className={classNames(isMobile ? styles.sectionMobile : styles.section)}
    >
      {tags.length > 0 && (
        <Box className={classNames(isMobile ? styles.tagsMobile : styles.tags)}>
          {tagsJSX}
        </Box>
      )}
      <Grid
        container
        spacing={isMobile ? 0 : 3}
        zeroMinWidth
        justify="space-between"
        className={styles.grid}
      >
        {itemsJSX}
      </Grid>

      {previewMode ? (
        <Box textAlign="center">
          <OutboundLink to="https://paidy.com/column">
            <Button
              className={styles.loadMoreBtn}
              variant="outlined"
              color="primary"
            >
              もっと詳しく
            </Button>
          </OutboundLink>
        </Box>
      ) : (
        !hasLoadMore && (
          <PaginationColor
            pageCount={Math.ceil(items?.length / loadBy)}
            page={page}
            gotoPage={(selected) => setPage(selected)}
          />
        )
      )}
      {hasLoadMore && loadBy < items.length && (
        <Box textAlign="center">
          <Button
            className={classNames(styles.loadMoreBtn, loadMoreBtnClassName)}
            variant="outlined"
            color="primary"
            onClick={handleLoadMore}
          >
            {loadMoreBtnLabel}
          </Button>
        </Box>
      )}
    </section>
  );
};

ItemsSec.defaultProps = {
  previewMode: false,
  items: [],
  handleCustomLoadMore: undefined,
  isLoaded: undefined,
  loadBy: 9,
  selectedTag: null,
  centeredCardBackground: true,
  hasLoadMore: false,
  loadMoreBtnLabel: 'もっと見る',
  redirectOnLoadmore: false,
};

export default ItemsSec;
